import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProductionLocation } from '../../models/production-location';
import { ProductionLocationsService } from '../../services/production-locations.service';
import { ScheduleService } from '../../services/schedule.service';
import { SnackbarService } from '../../services/snackbar.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DndDropEvent } from 'ngx-drag-drop';
import { FinalQaCartDetailsComponent } from './final-qa-cart-details/final-qa-cart-details.component';
import { polyfill } from "mobile-drag-drop";
import { scrollBehaviourDragImageTranslateOverride } from "mobile-drag-drop/scroll-behaviour";
import { FinalQACart } from '../../models/final-qa-cart';
import { MatTabChangeEvent } from '@angular/material/tabs';

const INVALID_LOCATION_ID = -1;
const AUTO_REFRESH = 2 * 60 * 1000; // 2 MIN

@Component({
  selector: 'app-final-qa',
  templateUrl: './final-qa.component.html',
  styleUrls: ['./final-qa.component.less']
})
export class FinalQaComponent implements OnInit, OnDestroy {

  tvMode = false;
  loading = false;
  autoRefresh = true;
  cartDropped = false;
  fullScreenMode = false;
  autoRefreshIntervalId = -1;
  draggingCartId: number = -1;
  completeActionSelected = true;
  productionLocationId = INVALID_LOCATION_ID;
  productionLocations: ProductionLocation[] = [];
  finalQACarts: FinalQACart[] = [];
  finalQARecentlyCompletedCarts: FinalQACart[] = [];

  constructor(
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    private scheduleService: ScheduleService,
    private snackBarService: SnackbarService,
    private productionLocationsService: ProductionLocationsService,
  ) { }

  async ngOnInit() {
    try {
      this.showLoading();
      this.fullScreenMode = this.getFullScreenMode();
      await this.getProductionLocations();
      await this.getAllFinalQACarts();
      this.setAutoRefresh();
      // allow drag and drop on touch devices
      polyfill({
        dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
      });
    } catch (err) {
      console.error('Error loading scheduled units:', err);
    } finally {
      this.hideLoading();
    }
  }

  ngOnDestroy() {
    clearInterval(this.autoRefreshIntervalId);
  }

  onDragStart(uniqueUnitId: number) {
    this.draggingCartId = uniqueUnitId;
  }

  onDragEnd() {
    this.draggingCartId = -1;
  }

  onDragCanceled() {
    this.draggingCartId = -1;
  }

  async onDrop(event: DndDropEvent) {
    const id = event.data;
    this.cartDropped = true;

    try {
      console.log("Final QA cart dropped. UniqueUnitId:", id);
      if (this.completeActionSelected) {
        await this.markAsFinalQACompleted(id);
      } else {
        await this.undoMarkAsFinalQACompleted(id);
      }
      this.cartDropped = false;
    } catch (err) {
      this.cartDropped = false;
      console.error(`Final QA cart dropped error. UniqueUnitId:${id}`, err);
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange() {
    this.fullScreenMode = this.getFullScreenMode();
  }

  setAutoRefresh() {
    // set auto refresh
    this.autoRefreshIntervalId = setInterval(async () => {
      await this.refresh();
    }, AUTO_REFRESH);
  }

  tabChanged($event: MatTabChangeEvent) {
    this.completeActionSelected = $event.index === 0;
  }

  async markAsFinalQACompleted(uniqueUnitId: number) {
    try {
      const result = await this.scheduleService.markAsFinalQACompleted(uniqueUnitId).toPromise();
      if (result) {
        this.snackBarService.showSuccess('Final QA cart marked as completed.');
        await this.getAllFinalQACarts();
      } else {
        this.snackBarService.showError('Error marking Final QA cart as completed. Please try again.');
      }
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error marking Final QA cart as completed.');
    }
  }

  async undoMarkAsFinalQACompleted(uniqueUnitId: number) {
    try {
      const result = await this.scheduleService.undoMarkAsFinalQACompleted(uniqueUnitId).toPromise();
      if (result) {
        this.snackBarService.showSuccess('Final QA cart marked as uncompleted.');
        await this.getAllFinalQACarts();
      } else {
        this.snackBarService.showError('Error marking Final QA cart as uncompleted. Please try again.');
      }
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error marking Final QA cart as uncompleted.');
    }
  }

  async refresh() {
    this.showLoading();
    await this.getAllFinalQACarts();
    this.hideLoading();
  }

  async getAllFinalQACarts(): Promise<void> {
    await this.getFinalQACarts();
    await this.getFinalQARecentlyCompletedCarts();
  }

  async getFinalQACarts(): Promise<void> {
    if (this.productionLocationId !== INVALID_LOCATION_ID) {
      try {
        this.finalQACarts = await this.scheduleService.getFinalQACarts(this.productionLocationId).toPromise();
      } catch (err) {
        console.error(err);
        this.snackBarService.showError('Error getting Final QA carts.');
      }
    } else {
      this.snackBarService.showError('Please select a production location.');
    }
  }

  async getFinalQARecentlyCompletedCarts(): Promise<void> {
    if (this.productionLocationId !== INVALID_LOCATION_ID) {
      try {
        this.finalQARecentlyCompletedCarts = await this.scheduleService.getFinalQARecentlyCompletedCarts(this.productionLocationId).toPromise();
      } catch (err) {
        console.error(err);
        this.snackBarService.showError('Error getting Final QA recently completed carts.');
      }
    } else {
      this.snackBarService.showError('Please select a production location.');
    }
  }

  getData(): FinalQACart[] {
    return this.finalQACarts;
  }

  getRecentlyCompletedData(): FinalQACart[] {
    return this.finalQARecentlyCompletedCarts;
  }

  scrollToTop() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollTop) {
      elem.scrollTop = 0;
    }
  }

  scrollToDown() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
    }
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }
  getFullScreenMode(): boolean {
    return this.document.fullScreen || this.document.mozFullScreen || this.document.webkitIsFullScreen;
  }

  toggleAutoRefresh(e: MatSlideToggleChange) {
    clearInterval(this.autoRefreshIntervalId);
    this.autoRefresh = e.checked;
    if (this.autoRefresh) {
      this.setAutoRefresh();
    }
  }

  openFinalQACartDetails(item: FinalQACart) {
    if (item) {
      this.dialog.open(FinalQaCartDetailsComponent, {
        width: '780px',
        data: item,
      });
    }
  }

  exitFullScreenMode() {
    const elem: any = this.document;
    const methodToBeInvoked = elem.exitFullscreen || elem.webkitExitFullscreen
      || elem.mozCancelFullScreen
      || elem.msExitFullscreen;

    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  setFullScreenMode() {
    const elem: any = this.document.documentElement;
    const methodToBeInvoked = elem.requestFullscreen || elem.webkitRequestFullScreen
      || elem.mozRequestFullscreen
      || elem.msRequestFullscreen;
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  getProductionLocations(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.productionLocationsService.getActiveProductionLocations().subscribe((productionLocations: ProductionLocation[]) => {
        this.productionLocations = productionLocations;
        const defaultLocation = productionLocations.find(p => p.isDefault);
        this.productionLocationId = defaultLocation !== undefined ? defaultLocation.id : INVALID_LOCATION_ID;
        resolve();
      },
        err => {
          console.error(err);
          this.snackBarService.showError('Error getting production locations.');
          reject();
        });
    });
  }
}
