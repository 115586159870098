<h2 mat-dialog-title> Final QA Cart Details</h2>
<mat-dialog-content>
    <div class="legend">
        <p>
            <span *ngIf="finalQACart.type === 'fusion'" class="type fusion">* Fusion</span>
            <span *ngIf="finalQACart.type === 'multicolor'" class="type multicolor">* Multicolor</span>
            <span *ngIf="finalQACart.type === 'fusion-multicolor'" class="type fusion-multicolor">
                * Fusion - Multicolor
            </span>
        </p>
    </div>

    <table *ngIf="finalQACart" mat-table [dataSource]="getData()" class="mat-elevation-z0 table">
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="salesOrderNumber">
            <th mat-header-cell *matHeaderCellDef> SO # </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.salesOrderNumber}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="productionOrderNumber">
            <th mat-header-cell *matHeaderCellDef> PO # </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.productionOrderNumber}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="match">
            <th mat-header-cell *matHeaderCellDef> Match </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.match}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef> Color </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.color}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="style">
            <th mat-header-cell *matHeaderCellDef> Style </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.style}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="totalParts">
            <th mat-header-cell *matHeaderCellDef> Parts </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.totalParts}}</span>
            </td>
        </ng-container>
    </table>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close cdkFocusInitial>Close</button>
</mat-dialog-actions>