<h1 class="mat-h1">
  Final QA
  <div>
    <mat-slide-toggle [(ngModel)]="tvMode" labelPosition="before" (change)="toggleAutoRefresh($event)">
      TV
    </mat-slide-toggle>
    <mat-slide-toggle checked="true" labelPosition="before" (change)="toggleAutoRefresh($event)">
      Auto Refresh
    </mat-slide-toggle>
    <button mat-mini-fab color="primary" (click)="refresh()" matTooltip="Refresh">
      <mat-icon>refresh</mat-icon>
    </button>
    <button *ngIf="!fullScreenMode" mat-mini-fab color="primary" (click)="setFullScreenMode()"
      matTooltip="Fullscreen Mode">
      <mat-icon>fullscreen</mat-icon>
    </button>
    <button *ngIf="fullScreenMode" mat-mini-fab color="primary" (click)="exitFullScreenMode()"
      matTooltip="Exit Fullscreen Mode">
      <mat-icon>fullscreen_exit</mat-icon>
    </button>
  </div>
</h1>
<hr />
<br />

<div *ngIf="!loading && !tvMode" class="done-zone" [class.dropped]="cartDropped" dndDropzone dndEffectAllowed="move"
  (dndDrop)="onDrop($event)">
  <ng-container *ngIf="completeActionSelected">
    <p>Done Zone</p>
    <small>You can drag and drop matched carts to complete the Final QA status.</small>
  </ng-container>

  <ng-container *ngIf="!completeActionSelected">
    <p>Undo Zone</p>
    <small>You can drag and drop matched carts to rollback the Final QA Complete status.</small>
  </ng-container>
</div>

<div class="legend">
  <p>
    <span class="type fusion">* Fusion</span>
    <span class="type multicolor">* Multicolor</span>
    <span class="type fusion-multicolor">* Fusion - Multicolor</span>
  </p>
</div>

<div class="loading" *ngIf="loading">
  <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
</div>

<div class="container">
  <button class="scroll-down" mat-mini-fab color="primary" (click)="scrollToDown()" matTooltip="Scroll to Bottom">
    <mat-icon>arrow_downward</mat-icon>
  </button>

  <button class="scroll-up" mat-mini-fab color="primary" (click)="scrollToTop()" matTooltip="Scroll to Top">
    <mat-icon>arrow_upward</mat-icon>
  </button>

  <mat-tab-group *ngIf="!loading" (selectedTabChange)="tabChanged($event)">
    <mat-tab label="Complete">
      <h3 *ngIf="!loading && getData().length === 0">
        No Final QA cart to show.
      </h3>
      <div class="tab-content">
        <mat-card *ngFor="let cart of getData()" [dndDraggable]="cart.uniqueUnitId" dndEffectAllowed="move"
          [class.dragging]="cart.uniqueUnitId === draggingCartId" (dndStart)="onDragStart(cart.uniqueUnitId)"
          (dndCanceled)="onDragCanceled()" (dndEnd)="onDragEnd()">
          <mat-card-title [class]="cart.type">{{cart.match}}</mat-card-title>
          <mat-card-content>
            <div *ngFor="let item of cart.items" class="row">
              <span>{{item.color}}</span>
              <span>{{item.style}}</span>
              <span>{{item.totalParts}}</span>
            </div>
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-button (click)="openFinalQACartDetails(cart)">show details</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab label="Recently Completed">
      <h3 *ngIf="!loading && getRecentlyCompletedData().length === 0">
        No Final QA recently completed cart to show.
      </h3>
      <div class="tab-content">
        <mat-card *ngFor="let cart of getRecentlyCompletedData()" [dndDraggable]="cart.uniqueUnitId"
          dndEffectAllowed="move" [class.dragging]="cart.uniqueUnitId === draggingCartId"
          (dndStart)="onDragStart(cart.uniqueUnitId)" (dndCanceled)="onDragCanceled()" (dndEnd)="onDragEnd()">
          <mat-card-title [class]="cart.type">{{cart.match}}</mat-card-title>
          <mat-card-content>
            <div *ngFor="let item of cart.items" class="row">
              <span>{{item.color}}</span>
              <span>{{item.style}}</span>
              <span>{{item.totalParts}}</span>
            </div>
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-button (click)="openFinalQACartDetails(cart)">show details</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>