import { Component, Inject } from '@angular/core';
import { FinalQACart } from '../../../models/final-qa-cart';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-final-qa-cart-details',
  templateUrl: './final-qa-cart-details.component.html',
  styleUrls: ['./final-qa-cart-details.component.less']
})
export class FinalQaCartDetailsComponent {

  finalQACart: FinalQACart;
  displayedColumns: string[] = ['salesOrderNumber', 'productionOrderNumber', 'match', 'color', 'style', 'totalParts'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FinalQACart,
  ) {

    this.finalQACart = data;
  }

  getData() {
    const { salesOrderNumber, match } = this.finalQACart;
    return this.finalQACart.items.map(item => { return { salesOrderNumber, match, ...item }; });
  }
}
