<div *ngIf="showAnimations">
  <div *ngFor="let i of [].constructor(10)" class="snowflake">
    {{getSymbol()}}
  </div>
</div>
<div class="container" [class.christmas]="showAnimations">
  <div class="logo-container">
    <img class="hat" [class.hidden]="!showAnimations" src="/assets/images/hat.png" alt="Santa Hat">
    <img class="logo" src="/assets/images/logo.svg" alt="Qwikkit">
  </div>

  <mat-card class="mat-elevation-z6">
    <ul class="errors">
      <li *ngFor="let item of errorMessages">
        {{item}}
      </li>
    </ul>

    <form [formGroup]="signInForm" (ngSubmit)="submit()">
      <mat-form-field class="full-width">
        <input matInput placeholder="Username" formControlName="username">
        <mat-error *ngIf="signInForm.controls.username.invalid">{{getUsernameErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Password" type="password" formControlName="password">
        <mat-error *ngIf="signInForm.controls.password.invalid">{{getPasswordErrorMessage()}}</mat-error>
      </mat-form-field>

      <mat-card-actions>
        <button mat-raised-button color="accent">Sign In</button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
<footer>
  <p>v{{appVersion}}</p>
</footer>